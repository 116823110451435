import { useEffect, useState } from 'react';
import styles from '../styles/style.module.css';
import { getRegions, getCountries, getBundles, getBundleFilterData } from "../api";
import { BUNDLE_TYPE } from '../../../utils/constants';
import { FilterIcon, } from '../../../assets/images';
import MainLoder from '../../../components/mainLoader'
import search from "../../../assets/images/search.png"
import {
    Breadcrumb,
    Layout,
    BundleCard,
    PartnerSection,
} from '../../../components';
import MainLoader from '../../../components/mainLoader';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../../components/pagination';
import { decodeBase64 } from '../../../utils/secureToken';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import chit from "../../../assets/images/chit.png"
import left from "../../../assets/images/left.png"
import tower from "../../../assets/images/tower.png"
import { getCoupons } from '../../home/api';
import { GetGeoLoactions } from '../../../utils/GetGeoLocation';
import useAuth from '../../../lib/hooks/useAuth';

interface Params {
    regionType: number;
    limit: number;
    page: number;
    search: string;
    countryId?: string;
    couponId?: string;
    bundlesCombinedId?: String
    dataAmount?: number
    days?: number
}

const Esim = () => {
    let {countryName} = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // const couponId = queryParams.get('couponId');
    let countryId = queryParams.get('country');
    let topup: any = queryParams.get('topup');
    let viewall = queryParams.get('viewall');
    topup = decodeBase64(topup)
    const { isAuthenticated } = useAuth();
    const couponPlans = location?.state ? location?.state?.couponPlans : null
    const [countries, setCountries] = useState<any[]>([]);
    const [bundles, setBundles] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const currency = useSelector(((state: any) => state?.getCurrency?.currency));
    const [activeTab, setActiveTab] = useState<number>(1);
    const [counts, setCounts] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const navigate = useNavigate();
    const [activeCoupon, setActiveCoupon] = useState<any>({});
    const [couponId, setCouponId] = useState<any>();
    const [getCouponDetals, setGetCouponDetals] = useState<any>([]);
    const [esimFilter, setEsimFilter] = useState<any>({
        "coverage": "",
        "package": "",
        "validity": ""
    });

    const [esimFilterData, setEsimFilterData] = useState<any>();

    const topupDetails = topup && !topup?.error ? JSON.parse(topup) : '';

    const [searchParams, setSearchParams] = useState('');

    const handleSearchSubmit = (event: any) => {
        event.preventDefault();
        setSearchParams(event.target.value);
        navigate(`/esim/${event.target.value}`);
        if (event.target.value) {
            setCurrentPage(1);
            if (countryId || topupDetails?.country || viewall) {
                navigate(`/esim`, { replace: false });
            }
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        window.scrollTo(600, 600)
    };

    const pageRecords = 12
    useEffect(() => {
        if (isAuthenticated) getCoupons().then((res: any) => {
            setGetCouponDetals(res?.data)
        }).catch((error) => console.log(error))

        // getBundleFilterData({ search: searchParams }).then((res: any) => {
        //     setEsimFilterData(res?.data[0])
        // }).catch((error) => {
        //     toast.error(error?.data?.message || "Something went wrong!")
        // })

        if (viewall) setSearchParams(viewall)
    }, [])

    // useEffect(()=>{
    //     GetGeoLoactions()
    //         .then(async (data: any) => {
    //             const response:any = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${data.latitude}&lon=${data.longitude}&format=json`);
    //           const getCountryByGeo =  (await response.json())?.address?.country
    //           getCountryByGeo ? setSearchParams(getCountryByGeo) : setSearchParams('');
    //         })
    //         .catch((error) => {
    //             console.error('Error getting location:', error);
    //         })
    // },[])
    useEffect(() => {
        if (topup && topup?.error) {
            toast.error("Topup not exist, something went wrong!")
            setTimeout(() => {
                navigate(-2);
            }, 3000)
        }
        const params: Params = {
            regionType: activeTab,
            limit: pageRecords,
            page: currentPage,
            search: searchParams || ""
        };
        if (countryId || topupDetails?.country) {
            params.countryId = countryId ? countryId : topupDetails?.country
            setSearchParams('');
            params.regionType = 1;
            setActiveTab(1)
            window.scrollTo(600, 600)
        }
        if (searchParams || countryName) {
            params.search = searchParams ? searchParams : countryName as string
            setLoader(false)
        } else {
            setLoader(true)
            // couponId
        }
        if (couponId) {
            params.couponId = couponId
        }

        if (esimFilter?.coverage) {
            params.bundlesCombinedId = esimFilter?.coverage
        }
        if (esimFilter?.package) {
            params.dataAmount = esimFilter?.package
        }
        if (esimFilter?.validity) {
            params.days = esimFilter?.validity
        }

        getBundles(params).then(res => {
            setCounts(res.data.count)
            setBundles(res.data.bundles);
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        });

    }, [activeTab, currency, currentPage, countryId, searchParams, couponId, esimFilter, countryName]);

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    autoplay: true,  // Enables auto sliding
                    autoplaySpeed: 1000,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true,  // Enables auto sliding
                    autoplaySpeed: 1000,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    
    return (

        <Layout>
            <meta name="description" content="Commbitz offers global eSIM with unlimited data, instant QR activation, and 24/7 support in 190+ countries. Get seamless connectivity on your travels worldwide." />
            <meta name="keywords" content="global sim card unlimited data, global sim card, global esim, travel sim card, international sim card unlimited data, travel esim"></meta>
            <title>Commbitz eSIM Services | Connect Anywhere, Anytime</title>
            {loader &&
                <MainLoder />
            }
            <Breadcrumb />
            <div className={styles.esimBanner}>
                <div className='container'>
                    <div className={styles.esimBannerOuter}>
                        <h4>Commbitz eSIM stay connected anytime, anywhere!</h4>
                    </div>
                </div>
            </div>

            <section>

                <div className='container'>
                    {!topup && getCouponDetals && getCouponDetals?.length > 0 &&
                        <div className="mainCoupon">
                            <div className="row">
                                <div className={styles.PackHeadLeft}>
                                    <h4>Coupons</h4>
                                </div>
                                {couponId && activeCoupon &&
                                    <div className="col-3">
                                        <div className={styles.esimDetails} >
                                            <div className={styles.FirtPack}>
                                                <div className={styles.PackHead}>
                                                    <div className={styles.PackHeadLeft}>
                                                        <h4>{activeCoupon?.title}</h4>
                                                    </div>
                                                    <div className={styles.PackHeadRight}>
                                                        <span>OFF {activeCoupon?.discount}%</span>
                                                    </div>
                                                </div>
                                                <button className={styles.buySimBtn} onClick={() => {
                                                    setActiveCoupon({})
                                                    setCouponId('')
                                                }}>
                                                    {couponId == activeCoupon?._id ? 'Deactive' : 'Active'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={`${couponId ? 'col-9' : 'col-12'}`}>
                                    <Slider {...settings}>
                                        {getCouponDetals.map((list: any) => (
                                            <div className={styles.esimDetails} >
                                                <div className={styles.FirtPack}>
                                                    <div className={styles.PackHead}>
                                                        <div className={styles.PackHeadLeft}>
                                                            <h4>{list?.title}</h4>
                                                        </div>
                                                        <div className={styles.PackHeadRight}>
                                                            <span>OFF {list?.discount}%</span>
                                                        </div>
                                                    </div>
                                                    <button className={styles.buySimBtn} onClick={() => {
                                                        setActiveCoupon(list)
                                                        setCouponId(list?._id)
                                                    }} disabled={couponId == list?._id ? true : false}>
                                                        {couponId == list?._id ? 'Deactive' : 'Active'}
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>

                        </div>
                    }

                    <div className={styles.esminFilter}>
                        <div className={styles.FormGroup}>
                            <form className={styles.FormGroup} onSubmit={handleSearchSubmit}>
                                <input
                                    type="text"
                                    placeholder="Search here.."
                                    value={searchParams}
                                    onChange={handleSearchSubmit}
                                    style={{ color: "#ffffff" }}
                                />
                                <button type="submit">
                                    <img src={search} alt="search icon" />
                                </button>
                            </form>
                        </div>
                        <div className={styles.esimBlogsfl}>
                            <ul>
                                <li className={activeTab === 1 ? styles.active : ""}
                                    onClick={() => {
                                        setActiveTab(1);
                                        setSearchParams('');
                                        handlePageChange(1);
                                        if (countryId || topupDetails?.country) {
                                            navigate(`/esim`, { replace: false });
                                        }
                                    }}>
                                    <span>Local eSIM</span>
                                </li>
                                <li className={activeTab === 2 ? styles.active : ""}
                                    onClick={() => {
                                        setActiveTab(2);
                                        setSearchParams('');
                                        handlePageChange(1)
                                        if (countryId || topupDetails?.country) {
                                            navigate(`/esim`, { replace: false });
                                        }
                                    }}>  <span>MultiCountry</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* {
                        searchParams && (
                            <section className={styles.packlist} style={{ paddingBottom: "10px" }}>
                                <div className='container'>
                                    <div className={styles.packColor}>
                                        <h3>Select options <button onClick={() => {
                                            setEsimFilter({
                                                ...esimFilter,
                                                coverage: "",
                                                package: "",
                                                validity: ""
                                            });
                                        }} >Clear All</button></h3>
                                        <div className={styles.packListBox}>
                                            <h4>Coverage Countries</h4>
                                            <ul>
                                                {esimFilterData?.coverageCountries?.map((coverageItme: any) =>
                                                (
                                                    <li className={esimFilter?.coverage === coverageItme?._id ? styles.active : ""} onClick={() => {
                                                        setEsimFilter({
                                                            ...esimFilter,
                                                            coverage: coverageItme?._id
                                                        });
                                                    }}><span>Coverage : {coverageItme?.coverageCountriesCount} <i className="fas fa-info-circle"></i></span></li>
                                                )
                                                )}
                                            </ul>
                                        </div>
                                        <div className={styles.packListBox}>
                                            <h4>Data package</h4>
                                            <ul>
                                                {esimFilterData?.dataAmount?.map((dataPackage: any) =>
                                                (
                                                    <li className={esimFilter?.package === dataPackage ? styles.active : ""} onClick={() => {
                                                        setEsimFilter({
                                                            ...esimFilter,
                                                            package: dataPackage
                                                        });
                                                    }}><span>{dataPackage == -1 ? "Unlimited" : dataPackage < 1000 ? dataPackage + " MB" : (dataPackage / 1000) + " GB"}</span></li>
                                                )
                                                )}
                                            </ul>
                                        </div>
                                        <div className={styles.packListBox}>
                                            <h4>Validity</h4>
                                            <ul>

                                                {esimFilterData?.validityDays?.map((validity: any) =>
                                                (
                                                    <li className={esimFilter?.validity === validity ? styles.active : ""} onClick={() => {
                                                        setEsimFilter({
                                                            ...esimFilter,
                                                            validity: validity
                                                        });
                                                    }}><span>{validity == 1 ? validity + " Day" : validity + " Days"}</span></li>
                                                )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    } */}



                    {loader && <MainLoader />}
                    <div className='udadEsin'>
                        <div className={styles.esimBlogs}>
                            <div className='row'>
                                {bundles.length > 0 ?
                                    bundles.map((bundle, index) =>
                                        <BundleCard key={index} bundle={bundle} coupon={couponId} topup={topupDetails?.iccid ? topupDetails?.iccid : ''} />
                                    )
                                    :
                                    <div className={styles.notFount}>Esim not Found!</div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                <Pagination
                    count={counts} // You can replace this with the actual count received from the API
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    itemsPerPage={pageRecords}
                />
            </section>
            <PartnerSection />
        </Layout>
    );
};

export default Esim;